@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  @font-face {
    font-family: "SF Pro";
    font-weight: 400;
    src: local("SF Pro") url("./assets/fonts/SF-Pro/SF-Pro-Display-Regular.otf")
      format("otf");
  }
  @font-face {
    font-family: "SF Pro";
    font-weight: 700;
    src: local("SF Pro"),
      url("./assets/fonts/SF-Pro/SF-Pro-Display-Bold.otf") format("otf");
  }
  @font-face {
    font-family: "SF Pro";
    font-weight: 500;
    src: local("SF Pro"),
      url("./assets/fonts/SF-Pro/SF-Pro-Display-Semibold.otf") format("otf");
  }
  html {
    font-family: "SF Pro", system-ui, sans-serif;
  }
}
